'use client'

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Cell, Spacer, Text } from '@vinted/web-ui'

import { LinkifiedMessage } from 'components/LinkifiedMessage'
import TranslationControl from 'components/TranslationControl'

import { Translation } from 'constants/translation'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getTranslatedDescription } from 'data/api'
import useAbTest from 'hooks/useAbTest'
import useAbTestExposeTracking from 'hooks/useAbTestExposeTracking'

import { ItemPageDescriptionPluginModel } from './types'

type Content = {
  title: string
  description: string
}

type Props = {
  data: ItemPageDescriptionPluginModel
}

const ItemPageDescriptionPlugin = ({ data }: Props) => {
  const autotranslateItemAbTest = useAbTest({
    abTestName: 'autotranslate_item_machine_translations_v2_2',
  })
  const { locale } = useIntl()
  const { trackExpose } = useAbTestExposeTracking({
    abTestName: 'autotranslate_item_machine_translations_v2_2',
  })

  const [isContentTranslated, setIsContentTranslated] = useState(false)
  const [translatedContent, setTranslatedContent] = useState<Content | null>(null)

  const { itemId, description, localization, title, userLocale } = data

  useEffect(() => {
    if (!autotranslateItemAbTest) return
    if (!userLocale) return
    if (userLocale.substring(0, 2) === locale.substring(0, 2)) return

    trackExpose()
  }, [autotranslateItemAbTest, userLocale, locale, trackExpose])

  const activeContent = (isContentTranslated && translatedContent) || { title, description }

  const showOriginal = () => setIsContentTranslated(false)

  const translate = async () => {
    if (translatedContent) {
      setIsContentTranslated(true)

      return
    }

    const response = await getTranslatedDescription(itemId)

    if ('errors' in response) return
    if (!response?.description) return

    setTranslatedContent({ title: response.title, description: response.description })
    setIsContentTranslated(true)
  }

  const handleTranslate = (isTranslated: boolean) => (isTranslated ? showOriginal() : translate())

  return (
    <Cell styling={Cell.Styling.Tight}>
      <div itemProp="name">
        <Text text={activeContent.title} type={Text.Type.Title} />
      </div>

      <Spacer />

      <div className="u-text-wrap" itemProp="description">
        <Text format>
          <LinkifiedMessage text={activeContent.description} />
        </Text>
      </div>

      <Spacer />

      {localization !== Translation.None && (
        <>
          <TranslationControl
            isTranslated={isContentTranslated}
            autotranslate={localization === Translation.Auto}
            onClick={handleTranslate}
            tracking={{
              target: ClickableElement.TranslateDescription,
              targetDetails: JSON.stringify({
                item_id: itemId,
              }),
            }}
          />
          <Spacer />
        </>
      )}
    </Cell>
  )
}

export default ItemPageDescriptionPlugin

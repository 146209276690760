import { ItemSearchParamsDto, SimilarItemDto } from 'types/dtos'
import { ProductItemModel } from 'types/models'
import { getItemThumbnail } from 'data/utils/item'
import { ItemThumbnailSize } from 'constants/images'

import { transformItemPhotoDtos } from './item'
import { getDominantColor, transformPartialUserDtoToProductItemUser } from './product-item'
import { transformCurrencyAmountDto } from './currency-amount'
import { transformIconBadges } from './icon-badges'
import { transformItemBoxDto } from './item-box'

export const transformSimilarItemDtoToProductItem = (
  similarItem: SimilarItemDto,
): ProductItemModel & { search_tracking_params: ItemSearchParamsDto | null } => ({
  id: similarItem.id,
  title: similarItem.title,
  url: similarItem.url,
  photos: similarItem.photo ? transformItemPhotoDtos([similarItem.photo]) : [],
  viewCount: similarItem.view_count,
  favouriteCount: similarItem.favourite_count,
  brandTitle: similarItem.brand_title || '',
  sizeTitle: similarItem.size_title || '',
  user: transformPartialUserDtoToProductItemUser(similarItem.user),
  price: transformCurrencyAmountDto(similarItem.price),
  thumbnailUrl: similarItem.photo
    ? getItemThumbnail([similarItem.photo], ItemThumbnailSize.Large)
    : null,
  dominantColor: similarItem.photo ? getDominantColor([similarItem.photo]) : null,
  badge: similarItem.badge,
  isPromoted: similarItem.promoted,
  isDraft: similarItem.is_draft,
  isFavourite: similarItem.is_favourite,
  iconBadges: transformIconBadges(similarItem.icon_badges),
  search_tracking_params: similarItem.search_tracking_params,
  priceWithDiscount: similarItem.discount_price
    ? transformCurrencyAmountDto(similarItem.discount_price)
    : null,
  status: similarItem.status,
  serviceFee: similarItem.service_fee ? transformCurrencyAmountDto(similarItem.service_fee) : null,
  totalItemPrice: similarItem.total_item_price
    ? transformCurrencyAmountDto(similarItem.total_item_price)
    : undefined,
  itemBox: similarItem.item_box && transformItemBoxDto(similarItem.item_box),
})

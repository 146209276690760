'use client'

import { useEffect, useRef, useState } from 'react'
import { Button } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { markCheckoutLoadStartTime } from 'libs/utils/checkout'
import { ConversationInitiatorType } from 'constants/conversation'
import { ApplicableTransactionAction } from 'constants/transaction'
import { UiState } from 'constants/ui'

import { navigateToPage } from 'libs/utils/window'
import { CHECKOUT_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import useTracking from 'hooks/useTracking'
import useLocation from 'hooks/useLocation'
import useSession from 'hooks/useSession'
import useFetch from 'hooks/useFetch'
import { buyerBuyEvent, buyerWantItemEvent } from 'libs/common/event-tracker/events'
import useAuthModal from 'libs/common/auth-modal/useAuthModal'
import { getSearchSessionData } from 'libs/utils/search'
import { CONVERSATION_URL } from 'constants/routes/inbox'
import { getHomepageSessionIdFromUrl } from 'libs/utils/homepage'
import { useCanTransact } from 'pages/Item/hooks'
import { transformConversationResponse } from 'state/conversation/transformers'
import { createItemConversationThread } from 'data/api'

import { ItemPageBuyButtonModel } from './types'
import { useShowStickyButtons } from '../../hooks/useShowStickyButtons'

type Props = {
  data: ItemPageBuyButtonModel
  isStickyButton?: boolean
}

const ItemPageBuyButtonPlugin = ({ data, isStickyButton = false }: Props) => {
  const {
    itemId,
    sellerId,
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
    closetCountdownEndDate,
  } = data

  const translate = useTranslate()
  const { track } = useTracking()
  const { searchParams } = useLocation()
  const canTransact = useCanTransact({
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
    closetCountdownEndDate,
  })
  const { openAuthModal } = useAuthModal()

  const { user } = useSession()

  const { fetch: createConversation, transformedData: conversation } = useFetch(
    createItemConversationThread,
    response => transformConversationResponse(response.conversation),
  )

  const isAuthenticated = !!user
  const currentUserId = user?.id
  const isViewingOwnItem = sellerId === currentUserId?.toString()

  const showStickyButtons = useShowStickyButtons({ isViewingOwnItem })

  const [uiState, setUiState] = useState(UiState.Idle)

  const referrerScreen = searchParams.referrer?.toString()
  const searchSessionData = getSearchSessionData()
  const searchSessionIdRef = useRef(
    referrerScreen === Screen.Catalog ? searchSessionData.searchSessionId : null,
  )

  const isButtonHidden = isViewingOwnItem || !canTransact || showStickyButtons !== isStickyButton

  useEffect(() => {
    if (isButtonHidden) return
    if (!conversation?.transaction) return
    if (uiState !== UiState.Pending) return

    const { transaction } = conversation

    track(
      buyerWantItemEvent({
        transactionId: transaction.id,
        itemId: transaction.itemId,
        button: 'buy',
        globalSearchSessionId: searchSessionData.globalSearchSessionId,
        searchSessionId: searchSessionIdRef.current,
        homepageSessionId: getHomepageSessionIdFromUrl(),
      }),
    )

    if (transaction.availableActions.includes(ApplicableTransactionAction.Buy)) {
      track(
        buyerBuyEvent({
          transactionId: transaction.id,
          screen: 'item',
          globalSearchSessionId: searchSessionData.globalSearchSessionId,
          homepageSessionId: getHomepageSessionIdFromUrl(),
        }),
      )
      navigateToPage(CHECKOUT_URL(transaction.id))
    } else {
      navigateToPage(CONVERSATION_URL(conversation.id))
    }
  }, [conversation, searchSessionData.globalSearchSessionId, track, uiState, isButtonHidden])

  const handleClick = () => {
    if (!isAuthenticated) {
      openAuthModal()

      return
    }

    setUiState(UiState.Pending)

    markCheckoutLoadStartTime({ itemId: Number(itemId) })

    createConversation({
      itemId,
      receiverId: sellerId,
      initiator: ConversationInitiatorType.Buy,
    })
  }

  if (isButtonHidden) return null

  return (
    <Button
      styling={Button.Styling.Filled}
      text={translate('item.actions.buy')}
      onClick={handleClick}
      testId="item-buy-button"
      disabled={uiState === UiState.Pending}
      isLoading={uiState === UiState.Pending}
    />
  )
}

export default ItemPageBuyButtonPlugin

'use client'

import { Label, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { MEMBER_PROFILE_URL } from 'constants/routes'
import useTracking from 'hooks/useTracking'
import { profileClickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { ItemPageClosetTitlePluginModel } from './types'

const MAX_ITEM_COUNT = 6

type Props = {
  data: ItemPageClosetTitlePluginModel | undefined
  showSuffix: boolean
}

const ItemPageClosetTitlePlugin = ({ data, showSuffix }: Props) => {
  const translate = useTranslate('item')
  const { track } = useTracking()

  if (!data) return null
  if (data.itemCount <= 1) return null

  const handleProfileClick = () => {
    track(
      profileClickEvent({
        target: ClickableElement.ViewAllUserItemsTitleCta,
        ownerId: data.itemUserId.toString(),
      }),
    )
  }

  return (
    <Label
      text={
        <Text type={Text.Type.Subtitle}>
          {translate('owner_items_title', { count: data.itemCount })}
        </Text>
      }
      suffix={
        showSuffix &&
        data.itemCount > MAX_ITEM_COUNT && (
          <a href={MEMBER_PROFILE_URL(data.itemUserId)} onClick={handleProfileClick}>
            <Text
              type={Text.Type.Subtitle}
              theme="primary"
              text={translate('view_all_items')}
              as="span"
            />
          </a>
        )
      }
    />
  )
}

export default ItemPageClosetTitlePlugin

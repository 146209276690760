'use client'

import { useSelector } from 'react-redux'
import { Badge, Button, Cell, Divider, Image, Rating, Spacer } from '@vinted/web-ui'
import {
  CheckCircle16,
  Clock16,
  Envelope16,
  LocationPin16,
  Umbrella16,
} from '@vinted/monochrome-icons'
import { InView } from 'react-intersection-observer'

import useDate from 'hooks/useDate'
import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useAbTest from 'hooks/useAbTest'

import { MEMBER_PROFILE_URL } from 'constants/routes'
import { NEW_MESSAGE_URL } from 'constants/routes/inbox'
import { calculateRating } from 'data/utils/user'
import { isDateInTheFuture } from 'libs/utils/date'
import { urlWithParams } from 'libs/utils/url'
import * as selectors from 'state/session/selectors'
import { UserDto } from 'types/dtos'
import { profileClickEvent, viewEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getIsBusinessAccount } from 'state/session/selectors'
import { getScreenName } from 'state/screen/selectors'

import FollowButton from 'components/FollowButton'
import SeparatedList from 'components/SeparatedList'
import UserImage from 'components/UserImage'
import UserTitle from 'components/UserTitle'
import UserBadge from 'components/UserBadge'
import { ViewableElement } from 'constants/tracking/viewable-elements'

import UserInfoLine from './UserInfoLine'
import { getUserLocationDescription } from './utils'

type Props = {
  user: UserDto | null
  showActions: boolean
  testId?: string
  showUserBadge?: boolean
}

const ItemUserInfo = ({ user, showActions = true, testId, showUserBadge }: Props) => {
  const translate = useTranslate()
  const { formatRelativeTime } = useDate()
  const { track } = useTracking()
  const { relativeUrl } = useLocation()

  const currentUser = useSelector(selectors.getUser)
  const isCurrentUserBusiness = useSelector(getIsBusinessAccount)

  const isUserBadgeEnabled = useSelector(
    getIsFeatureSwitchEnabled('active_lister_badge_buyer_side_web'),
  )
  const screen = useSelector(getScreenName)

  const isViewingSelf = currentUser?.id === user?.id

  const shouldTrackExposeListerBadge =
    isUserBadgeEnabled && !isViewingSelf && !isCurrentUserBusiness && user?.business === false

  const activeListerBadgeBuyerSideAbTest = useAbTest({
    abTestName: 'active_lister_badge_buyer_side',
    shouldTrackExpose: shouldTrackExposeListerBadge,
  })

  const handleOnClick = () => {
    if (!user) return

    track(
      profileClickEvent({
        target: ClickableElement.UserShortInfoCell,
        ownerId: user.id.toString(),
      }),
    )
  }

  const renderHeader = () => {
    if (!user) return null

    const rating = calculateRating(user.feedback_reputation)

    return (
      <Cell
        prefix={
          <UserImage src={user.photo?.url} styling={Image.Styling.Circle} size={Image.Size.Large} />
        }
        title={
          <UserTitle
            businessAccountName={user.business_account?.name}
            login={user.login}
            isBusiness={user.business}
            isBody
            isBottomLoginHidden
            isBadgeHidden
          />
        }
        onClick={handleOnClick}
        url={MEMBER_PROFILE_URL(user.id)}
        type={Cell.Type.Navigating}
        testId={testId ? `${testId}-header` : undefined}
        chevron
      >
        <Rating
          aria={{
            'aria-label':
              (rating && translate('user.a11y.rating', { rating, max_rating: 5 })) || '',
          }}
          value={rating}
          text={user.feedback_count}
          size={Rating.Size.Small}
          emptyStateText={translate('user.feedback.no_reviews')}
        />
        {user.business && (
          <>
            <Spacer size={Spacer.Size.Small} />
            <Badge content={translate('business.title')} testId="seller-pro-badge" />
          </>
        )}
      </Cell>
    )
  }

  const renderBusinessEmail = () => {
    if (!user) return null

    const isBusinessAccount = user.business
    const businessEmail = isBusinessAccount && user.business_account?.email

    const hidePersonalInfo =
      isBusinessAccount && user.business_account?.entity_type === 'sole_trader'

    if (!businessEmail) return null

    if (businessEmail && hidePersonalInfo) {
      return (
        <UserInfoLine iconName={Envelope16} truncate>
          {businessEmail}
        </UserInfoLine>
      )
    }

    return (
      <UserInfoLine iconName={Envelope16} truncate>
        <a href={`mailto:${businessEmail}`} title={businessEmail} data-testid="seller-email-link">
          {businessEmail}
        </a>
      </UserInfoLine>
    )
  }

  const renderInfo = () => {
    if (!user) return null

    const isBusinessAccount = user.business
    const businessLegalCode = isBusinessAccount && user.business_account?.legal_code

    const handleLastSeenView = (inView: boolean) => {
      if (!inView) return

      track(
        viewEvent({
          screen,
          target: ViewableElement.UserLastSeen,
        }),
      )
    }

    return (
      <Cell testId={testId ? `${testId}-info` : undefined}>
        <SeparatedList separator={<Spacer size={Spacer.Size.Small} />}>
          <UserInfoLine iconName={LocationPin16}>{getUserLocationDescription(user)}</UserInfoLine>
          {renderBusinessEmail()}
          {businessLegalCode && (
            <UserInfoLine iconName={CheckCircle16}>
              <span data-testid="seller-legal-code">
                {`${translate('user.verification.legal_code', {
                  count: user.msg_template_count,
                })} ${businessLegalCode}`}
              </span>
            </UserInfoLine>
          )}
          {user.is_on_holiday && (
            <UserInfoLine iconName={Umbrella16}>
              <span>{translate('user.on_holiday')}</span>
            </UserInfoLine>
          )}
          {user.last_loged_on_ts && (
            <InView onChange={handleLastSeenView}>
              <UserInfoLine iconName={Clock16}>
                <span suppressHydrationWarning>
                  {`${translate('user.last_seen')} ${formatRelativeTime(user.last_loged_on_ts)}`}
                </span>
              </UserInfoLine>
            </InView>
          )}
        </SeparatedList>
      </Cell>
    )
  }

  const renderActions = () => {
    if (!user) return null
    if (!currentUser || currentUser.id === user.id) return null
    if (isDateInTheFuture(user.closet_countdown_end_date)) return null

    if (user.hates_you || user.is_hated) {
      return (
        <Cell testId={testId ? `${testId}-actions` : undefined}>
          <FollowButton userId={user.id} isActive={user.is_favourite} isFlat />
        </Cell>
      )
    }

    const messageUrl = urlWithParams(NEW_MESSAGE_URL, {
      receiver_id: user.id,
      ref_url: relativeUrl,
    })

    return (
      <Cell testId={testId ? `${testId}-actions` : undefined}>
        <div className="u-flexbox">
          <Button
            text={translate('user.action.write_message')}
            styling={Button.Styling.Flat}
            url={messageUrl}
          />
          <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
          <Divider orientation={Divider.Orientation.Vertical} />
          <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
          <FollowButton userId={user.id} isActive={user.is_favourite} isFlat />
        </div>
      </Cell>
    )
  }

  const renderUserBadge = () => {
    if (!user) return null
    if (
      !shouldTrackExposeListerBadge ||
      !showUserBadge ||
      activeListerBadgeBuyerSideAbTest?.variant !== 'on'
    )
      return null
    if (!user.seller_badges?.length) return null

    return <UserBadge />
  }

  if (!user) return null

  return (
    <SeparatedList separator={<Divider />}>
      {renderHeader()}
      {renderUserBadge()}
      {renderInfo()}
      {showActions && renderActions()}
    </SeparatedList>
  )
}

export default ItemUserInfo

'use client'

import { ReactNode } from 'react'
import { Divider, Text } from '@vinted/web-ui'

import { FormattedDateMessage } from 'components/@support'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import {
  CONDITION_FAQ_ID,
  MEASUREMENTS_FAQ_ID,
  VIDEO_GAME_CONTENT_RATING_FAQ_ID,
} from 'constants/item-details'
import { INBOX_ITEM_PATH } from 'constants/routes/inbox'
import useTranslate from 'hooks/useTranslate'
import useBreakpoint from 'hooks/useBreakpoint'
import { transformItemDescriptionAttributesDto } from 'data/transformers/item-attributes'
import useSession from 'hooks/useSession'

import { ItemPageAttributesBlockPluginModel } from './types'
import Brand from './Brand'
import MoreInfoModal from './MoreInfoModal'
import ItemInfoLink from './ItemInfoLink'

type ItemAttribute = {
  code: string
  title: string
  value?: ReactNode | null
  faqEntryId?: string | null
  expandable?: boolean
  trackingTarget?: ClickableElement
}

type Props = {
  data: ItemPageAttributesBlockPluginModel
}

const ItemPageAttributesBlockPlugin = ({ data }: Props) => {
  const {
    brandDto,
    user,
    id,
    measurementWidth,
    measurementLength,
    color1,
    color2,
    acceptedPayInMethods,
    descriptionAttributes,
    activeBidCount,
    size,
    status,
    measurementUnit,
    videoGameRating,
    sizeGuideFaqEntryId,
    author,
    isbn,
    bookTitle,
    viewCount,
    favouriteCount,
    createdAtTs,
  } = data
  const breakpoints = useBreakpoint()
  const translate = useTranslate('item.details')

  const currentUserId = useSession().user?.id

  const isViewingOwnItem = user.id === currentUserId

  const getMeasurements = () => {
    const unit = measurementUnit?.toUpperCase() || translate('measurements.unit')

    const titles: Array<string> = []

    if (measurementWidth)
      titles.push(translate('measurements.width_shorthand', { width: measurementWidth, unit }))

    if (measurementLength)
      titles.push(translate('measurements.length_shorthand', { length: measurementLength, unit }))

    return titles.join(' / ')
  }

  const getColours = () => {
    if (!color1) return null

    if (!color2) return color1

    return `${color1}, ${color2}`
  }

  const getLocation = () => {
    const { expose_location, city, country_title_local } = user

    if (expose_location && city) {
      return `${city}, ${country_title_local}`
    }

    return country_title_local
  }

  const getInterestedCountElement = () => {
    const interestedTranslationKeyTitle =
      activeBidCount === 1 ? 'interested_member' : 'interested_member_with_count'

    if (!isViewingOwnItem) {
      return translate(interestedTranslationKeyTitle, { count: activeBidCount })
    }

    return (
      <a
        href={INBOX_ITEM_PATH(id)}
        className="inverse u-disable-underline-without-hover"
        itemProp="url"
        data-testid="item-attributes-interested-count-link"
      >
        {translate(interestedTranslationKeyTitle, { count: activeBidCount })}
      </a>
    )
  }

  const showMeasurements = measurementWidth || measurementLength

  const transformedDescriptionAttributes =
    transformItemDescriptionAttributesDto(descriptionAttributes)

  const itemAttributes: Array<ItemAttribute> = [
    {
      code: 'video_game_rating',
      title: translate('video_game_rating'),
      value: videoGameRating,
      faqEntryId: VIDEO_GAME_CONTENT_RATING_FAQ_ID,
    },
    {
      code: 'size',
      title: translate('size'),
      value: size,
      faqEntryId: sizeGuideFaqEntryId?.toString(),
    },
    {
      code: 'measurements',
      title: translate('measurements.title'),
      value: showMeasurements && getMeasurements(),
      faqEntryId: MEASUREMENTS_FAQ_ID,
    },
    {
      code: 'condition',
      title: translate('condition'),
      value: status,
      faqEntryId: CONDITION_FAQ_ID,
    },
    ...transformedDescriptionAttributes,
    {
      code: 'color',
      title: translate('color'),
      value: (color1 || color2) && getColours(),
    },
    {
      code: 'author',
      title: translate('author'),
      value: author,
    },
    {
      code: 'book_title',
      title: translate('book_title'),
      value: bookTitle,
    },
    {
      code: 'isbn',
      title: translate('isbn'),
      value: isbn,
    },
    {
      code: 'manufacturer',
      title: translate('manufacturer'),
      value: data.manufacturer,
      expandable: true,
      trackingTarget: ClickableElement.ManufacturerCredentials,
    },
    {
      code: 'manufacturer_labelling',
      title: translate('manufacturer_label'),
      value: data.manufacturerLabel,
      expandable: true,
      trackingTarget: ClickableElement.LabelingAndMarkingInfo,
    },
  ]

  const paymentMethods = acceptedPayInMethods.map(option => option.translated_name).join(', ')

  const itemDetails: Array<ItemAttribute> = [
    {
      code: 'location',
      title: translate('location'),
      value: getLocation(),
    },
    {
      code: 'payment_methods',
      title: translate('payment_methods'),
      value: acceptedPayInMethods.length > 0 && paymentMethods,
    },
    {
      code: 'view_count',
      title: translate('views'),
      value: viewCount || '0',
    },
    {
      code: 'favourite_count',
      title: translate('favourites'),
      value: isViewingOwnItem && favouriteCount,
    },
    {
      code: 'interested_count',
      title: translate('interested'),
      value: activeBidCount > 0 && getInterestedCountElement(),
    },
    {
      code: 'uploaded_date',
      title: translate('uploaded'),
      value: <FormattedDateMessage date={new Date(createdAtTs)} relativeDate />,
    },
  ]

  const renderItemAttributes = ({
    code,
    title,
    value,
    faqEntryId,
    expandable,
    trackingTarget,
  }: ItemAttribute) => {
    if (!value) return null

    return (
      <div
        className="details-list__item u-position-relative"
        key={code}
        data-testid={`item-attributes-${code}`}
      >
        <div className="details-list__item-title">{title}</div>
        <div className="details-list__item-value" itemProp={code}>
          {expandable ? (
            <span className="details-list__item-value--expandable">{value}</span>
          ) : (
            value
          )}

          {faqEntryId && (
            <ItemInfoLink
              faqEntryId={Number(faqEntryId)}
              accessChannel="product_link"
              code={code}
              itemId={id}
            />
          )}

          {expandable && (
            <MoreInfoModal
              code={code}
              title={title}
              body={<Text text={value} />}
              trackingTarget={trackingTarget}
              itemId={id}
            />
          )}
        </div>
      </div>
    )
  }

  const renderItemDetails = ({ code, title, value }: ItemAttribute) => {
    if (!value) return null

    return (
      <div className="details-list__item" data-testid={`item-details-${code}`} key={code}>
        <div className="details-list__item-title">{title}</div>
        <div className="details-list__item-value">{value}</div>
      </div>
    )
  }

  return (
    <div className="details-list details-list--details">
      {!breakpoints.tablets && (
        <Divider testId="item-details-divider" className="u-margin-bottom-small" />
      )}
      {brandDto && <Brand brandDto={brandDto} itemId={id} />}
      {itemAttributes.map(renderItemAttributes)}
      {itemDetails.map(renderItemDetails)}
    </div>
  )
}

export default ItemPageAttributesBlockPlugin

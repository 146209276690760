import { ItemDescriptionAttributeDto } from 'types/dtos'

export const transformDescriptionAttributeDto = (
  descriptionAttribute: ItemDescriptionAttributeDto,
) => ({
  code: descriptionAttribute.code,
  title: descriptionAttribute.title,
  value: descriptionAttribute.value,
  faqEntryId: descriptionAttribute.faq_id,
})

export const transformItemDescriptionAttributesDto = (
  descriptionAttributes: Array<ItemDescriptionAttributeDto>,
) => descriptionAttributes.map(transformDescriptionAttributeDto)

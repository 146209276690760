'use client'

import { useSelector } from 'react-redux'
import { Spacer } from '@vinted/web-ui'

import ItemPageBuyerProtectionInfoPlugin from 'pages/Item/plugins/BuyerProtectionInfo'
import ItemPageShippingPricePlugin from 'pages/Item/plugins/ShippingPrice'
import ItemPageDescriptionPlugin from 'pages/Item/plugins/Description'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { PluginName } from 'pages/Item/types'

import { ItemDto, ItemPagePluginEntityDto } from 'types/dtos'
import { Plugins } from 'pages/Item/hooks'

import ItemPagePricingPlugin from '../../plugins/Pricing/ItemPagePricingPlugin'
import ItemActions from '../../ItemActions'
import ItemPageOfflineVerificationBlockPlugin from '../../plugins/OfflineVerificationBlock'
import ItemPageAttributesBlockPlugin from '../../plugins/AttributesBlock'
import ItemElectronicsVerificationBlockPlugin from '../../plugins/ElectronicsVerificationBlock'
import Plugin from '../../plugins/Plugin'

type Props = {
  item: ItemDto
  data: Plugins | null
  serverSidePlugins?: Array<ItemPagePluginEntityDto>
}

const DetailsSection = ({ item, data, serverSidePlugins }: Props) => {
  const dynamicAttributesEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_dynamic_attributes_block'),
  )

  return (
    <>
      <div className="details-list details-list--main-info">
        {data?.pricing && <ItemPagePricingPlugin data={data.pricing} />}
        {dynamicAttributesEnabled ? (
          <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Attributes)} />
        ) : (
          data?.attributesBlock && <ItemPageAttributesBlockPlugin data={data.attributesBlock} />
        )}
      </div>
      <div className="details-list details-list--info">
        {data?.description && <ItemPageDescriptionPlugin data={data.description} />}
        {data?.shippingPrice && <ItemPageShippingPricePlugin data={data.shippingPrice} />}
        <Spacer size={Spacer.Size.Large} />
        <div className="details-list__item details-list--actions">
          <ItemActions item={item} />
          <Spacer />
        </div>
        <div className="details-list--shipping-info">
          {data?.offlineVerificationBlock && (
            <ItemPageOfflineVerificationBlockPlugin data={data.offlineVerificationBlock} />
          )}
          {data?.electronicsVerificationBlock && (
            <ItemElectronicsVerificationBlockPlugin data={data.electronicsVerificationBlock} />
          )}
          {data?.buyerProtectionInfo && (
            <ItemPageBuyerProtectionInfoPlugin data={data.buyerProtectionInfo} />
          )}
        </div>
      </div>
    </>
  )
}

export default DetailsSection

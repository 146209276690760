'use client'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ItemUserInfo from 'components/ItemUserInfo'
import { fetchBannersRequest } from 'state/banners/actions'
import { getBanners } from 'state/banners/selectors'
import { getUserId } from 'state/session/selectors'

import { ItemPageUserInfoPluginDto } from './types'

type Props = {
  data: ItemPageUserInfoPluginDto
}

const ItemPageUserInfoPlugin = ({ data }: Props) => {
  const currentUserId = useSelector(getUserId)
  const banners = useSelector(getBanners)
  const dispatch = useDispatch()
  const isViewingSelf = currentUserId === data?.user.id

  useEffect(() => {
    if (isViewingSelf) return

    dispatch(fetchBannersRequest())
  }, [dispatch, isViewingSelf])

  return <ItemUserInfo showActions={!banners.portalMergeItemViewBanner} {...data} showUserBadge />
}

export default ItemPageUserInfoPlugin

'use client'

import { useRef } from 'react'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'
import { Spacer } from '@vinted/web-ui'

import { ProductItem } from 'components/ProductItem'
import { ItemSearchParamsDto } from 'types/dtos'
import { addClickListItemTracking } from 'containers/TrackingProvider'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ClickListItemEventArgs, impressionEvent } from 'libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking'
import { getSearchSessionData } from 'libs/utils/search'
import { Screen } from 'constants/tracking/screens'
import { getHomepageSessionIdFromUrl, withHomepageSessionIdFromUrl } from 'libs/utils/homepage'
import { ProductItemModel } from 'types/models'
import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'
import HorizontallyScrollableItems from 'components/HorizontallyScrollableItems'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'

const MAX_NUMBER_OF_ITEMS = 6

const TrackedProductItem = addClickListItemTracking(ProductItem)
type ItemModel = ProductItemModel & { search_tracking_params?: ItemSearchParamsDto | null }

type Props = {
  items: Array<ItemModel>
  contentSource: ContentSource.SimilarItems | ContentSource.OtherUserItems
  refererScreen: string
  horizontalScrollEnabled: boolean
  onCtaClick(): void
  itemTestId?: string
  ctaUrl?: string
  onCtaView(): void
}

const ItemViewItems = ({
  items,
  contentSource,
  refererScreen,
  itemTestId,
  ctaUrl,
  horizontalScrollEnabled,
  onCtaClick,
  onCtaView,
}: Props) => {
  const { track } = useTracking()
  const seenItemIds = useRef<Array<number>>([])
  const currentUserId = useSession().user?.id
  const translate = useTranslate()

  const searchSessionData = getSearchSessionData()
  const searchSessionIdRef = useRef(
    refererScreen === Screen.Catalog ? searchSessionData.searchSessionId : null,
  )
  const homepageSessionId = getHomepageSessionIdFromUrl()

  const handleItemVisibility = (item: ItemModel, position: number) => (inView: boolean) => {
    if (!inView) return

    const { id, user } = item
    const { id: itemOwnerId } = user
    const searchTrackingParams =
      'search_tracking_params' in item ? item.search_tracking_params : null

    if (seenItemIds.current.includes(id)) return

    track(
      impressionEvent({
        id,
        position,
        contentSource,
        itemOwnerId,
        globalSearchSessionId: searchSessionData.globalSearchSessionId,
        searchSessionId: searchSessionIdRef.current,
        contentType: ListItemContentType.Item,
        searchScore: searchTrackingParams?.score,
        searchSignals: searchTrackingParams?.matched_queries,
        itemDistance: searchTrackingParams?.buyer_item_distance_km,
        homepageSessionId,
      }),
    )

    seenItemIds.current.push(id)
  }

  const renderItem = (item: ItemModel, index: number) => {
    const position = index + 1
    const { id } = item
    const searchTrackingParams =
      'search_tracking_params' in item ? item.search_tracking_params : null
    const trackingArgs: ClickListItemEventArgs = {
      id,
      position,
      contentSource,
      globalSearchSessionId: searchSessionData.globalSearchSessionId,
      searchSessionId: searchSessionIdRef.current,
      contentType: ListItemContentType.Item,
      searchScore: searchTrackingParams?.score,
      searchSignals: searchTrackingParams?.matched_queries,
      itemDistance: searchTrackingParams?.buyer_item_distance_km,
      homepageSessionId,
    }

    return (
      <InView
        onChange={handleItemVisibility(item, position)}
        key={id}
        className={classNames({
          'item-view-items__item': !horizontalScrollEnabled,
        })}
      >
        <TrackedProductItem
          item={item}
          showFavourite
          testId={itemTestId && `${itemTestId}-${id}`}
          tracking={trackingArgs}
          viewingSelf={currentUserId === item.user.id}
          hideBusinessBadge={contentSource === ContentSource.OtherUserItems}
          url={withHomepageSessionIdFromUrl(item.url)}
          isBumpTextHidden
          showStatus
        />
      </InView>
    )
  }

  if (horizontalScrollEnabled)
    return (
      <div data-testid="item-page-closet-plugin" className="item-page-horizontal-block">
        <Spacer size={Spacer.Size.Large} />
        <HorizontalScrollArea
          itemsFullWidthAlignment
          controlsScrollType={ControlScrollType.Partial}
        >
          {items.slice(0, MAX_NUMBER_OF_ITEMS).map((item, index) => (
            <HorizontalScrollArea.Item className="item-view-items-horizontal__item" key={item.id}>
              {renderItem(item, index)}
            </HorizontalScrollArea.Item>
          ))}

          {ctaUrl && items.length > MAX_NUMBER_OF_ITEMS && (
            <HorizontalScrollArea.Item className="item-view-items-horizontal__item item-view-items-horizontal__cta">
              <HorizontallyScrollableItems.CtaItem
                url={ctaUrl}
                onClick={onCtaClick}
                content={translate('item.view_all_items')}
                testId={itemTestId && `${itemTestId}-block-cta`}
                onEnter={onCtaView}
              />
            </HorizontalScrollArea.Item>
          )}
        </HorizontalScrollArea>
      </div>
    )

  return <div className="item-view-items">{items.map(renderItem)}</div>
}

export default ItemViewItems

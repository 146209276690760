'use client'

import { useEffect, useRef } from 'react'
import { InView } from 'react-intersection-observer'

import useFetch from 'hooks/useFetch'
import useTracking from 'hooks/useTracking'
import { getUserItems } from 'data/api'
import { profileClickEvent, viewEvent } from 'libs/common/event-tracker/events'

import { ContentSource } from 'constants/tracking/content-sources'
import ItemViewItems from 'components/ItemViewItems'
import ContentLoader from 'components/ContentLoader'
import { SellerFiltersType } from 'pages/Profile/UserItems/SellerFilters/utils'
import { transformItemDtoToProductItem } from 'data/transformers'
import { MEMBER_PROFILE_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'

import ViewAllUserItemsLink from './ViewAllUserItemsLink'
import { ItemPageClosetPluginDto } from './types'

type Props = {
  data: ItemPageClosetPluginDto
  referrerScreen: string
  horizontalScrollEnabled: boolean
  trackExposure(inView: boolean): void
}

const ItemPageClosetPlugin = ({
  data,
  referrerScreen,
  horizontalScrollEnabled,
  trackExposure,
}: Props) => {
  const { itemId, itemUserId } = data

  const { fetch: fetchUserItems, data: userItemsData, isLoading } = useFetch(getUserItems)
  const { track } = useTracking()
  const seenCta = useRef(false)

  useEffect(() => {
    fetchUserItems({
      userId: itemUserId,
      currentPage: 0,
      perPage: 21,
      filter: SellerFiltersType.Active,
      selectedItemId: itemId,
    })
  }, [itemUserId, fetchUserItems, itemId])

  const userItems = userItemsData?.items.filter(item => item.id !== itemId)

  if (isLoading) {
    return (
      <div className="u-ui-margin-top-medium">
        <ContentLoader testId="item-feed-loader" />
      </div>
    )
  }

  if (!userItems?.length) return null

  const handleClick = () => {
    track(
      profileClickEvent({
        target: ClickableElement.ViewAllUserItemsCardCta,
        ownerId: data.itemUserId.toString(),
        screen: Screen.Item,
      }),
    )
  }

  const handleCtaView = () => {
    if (seenCta.current) return

    seenCta.current = true
    track(viewEvent({ target: ViewableElement.ViewAllUserItemsCardCta, screen: Screen.Item }))
  }

  return (
    <InView as="div" data-testid="item-page-closet-plugin" onChange={trackExposure}>
      <ItemViewItems
        items={userItems.map(transformItemDtoToProductItem)}
        contentSource={ContentSource.OtherUserItems}
        itemTestId="owner-item"
        refererScreen={referrerScreen}
        ctaUrl={MEMBER_PROFILE_URL(itemUserId)}
        horizontalScrollEnabled={horizontalScrollEnabled}
        onCtaClick={handleClick}
        onCtaView={handleCtaView}
      />
      {!horizontalScrollEnabled && !!userItemsData?.pagination.total_entries && (
        <ViewAllUserItemsLink
          ownerItemCount={userItemsData.pagination.total_entries}
          ownerId={itemUserId}
        />
      )}
    </InView>
  )
}

export default ItemPageClosetPlugin

'use client'

import { useSelector } from 'react-redux'

import PortalMergeItemViewBanner from 'components/PortalMergeItemViewBanner'

import { useItemPlugins } from 'pages/Item/hooks'
import { getUserId } from 'state/session/selectors'
import { getBanners } from 'state/banners/selectors'
import { ItemDto } from 'types/dtos'

import ItemPageMakeOfferActionPlugin from '../plugins/MakeOfferAction/ItemPageMakeOfferActionPlugin'
import ItemPageEditActionPlugin from '../plugins/EditAction/ItemPageEditActionPlugin'
import ItemPageHideActionPlugin from '../plugins/HideAction/ItemPageHideActionPlugin'
import ItemPageDeleteActionPlugin from '../plugins/DeleteAction/ItemPageDeleteActionPlugin'
import ItemPageBumpActionPlugin from '../plugins/BumpAction/ItemPageBumpActionPlugin'
import ItemPageAskSellerActionPlugin from '../plugins/AskSellerAction/ItemPageAskSellerActionPlugin'
import ItemPageCountdownActionPlugin from '../plugins/ClosetCountdownAction/ItemPageClosetCountdownActionPlugin'
import ItemPageMarkAsReservedButtonPlugin from '../plugins/MarkAsReservedButton/ItemPageMarkAsReservedButtonPlugin'
import ItemPageMarkAsSoldButtonPlugin from '../plugins/MarkAsSoldButton/ItemPageMarkAsSoldButtonPlugin'
import ItemPageBuyButtonPlugin from '../plugins/BuyButton/ItemPageBuyButtonPlugin'

type Props = {
  item: ItemDto
}

const ItemActions = ({ item }: Props) => {
  const currentUserId = useSelector(getUserId)
  const { portalMergeItemViewBanner } = useSelector(getBanners)

  const plugins = useItemPlugins(item)

  const isViewingOwnItem = item.user.id === currentUserId

  if (!isViewingOwnItem && portalMergeItemViewBanner) {
    return (
      <PortalMergeItemViewBanner
        title={portalMergeItemViewBanner.ctaTitle}
        url={portalMergeItemViewBanner.ctaUrl}
        subtitle={portalMergeItemViewBanner.bottomText}
        bottomLinkTitle={portalMergeItemViewBanner.bottomTextLinkTitle}
        bottomLinkUrl={portalMergeItemViewBanner.bottomTextLinkUrl}
      />
    )
  }

  return (
    <div className="u-grid u-gap-regular">
      {plugins?.buyButton && <ItemPageBuyButtonPlugin data={plugins.buyButton} />}
      {plugins?.makeOfferAction && <ItemPageMakeOfferActionPlugin data={plugins.makeOfferAction} />}
      {plugins?.closetCountdownAction && (
        <ItemPageCountdownActionPlugin data={plugins.closetCountdownAction} />
      )}
      {plugins?.askSellerAction && <ItemPageAskSellerActionPlugin data={plugins.askSellerAction} />}
      <ItemPageBumpActionPlugin bumpActionPluginModel={plugins?.bumpAction ?? undefined} />
      <ItemPageMarkAsSoldButtonPlugin data={plugins?.markAsSoldButton ?? undefined} />
      <ItemPageMarkAsReservedButtonPlugin data={plugins?.markAsReservedButton ?? undefined} />
      <ItemPageHideActionPlugin hideActionPluginModel={plugins?.hideAction ?? undefined} />
      <ItemPageEditActionPlugin editActionPluginModel={plugins?.editAction ?? undefined} />
      <ItemPageDeleteActionPlugin deleteActionPluginModel={plugins?.deleteAction ?? undefined} />
    </div>
  )
}

export default ItemActions

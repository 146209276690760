import { ServiceFeeDto } from 'types/dtos'
import { ServiceFeeModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformServiceFeeDto = (dto: ServiceFeeDto): ServiceFeeModel => ({
  itemPricePercentage: dto.item_price_percentage,
  fixedAmount: transformCurrencyAmountDto(dto.fixed_amount),
  currencyCode: dto.currency_code,
})

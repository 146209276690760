'use client'

import { ReactNode } from 'react'
import { Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import FaqEntryUrl from 'components/FaqEntryUrl'

import { AccessChannel } from 'constants/index'
import { FaqEntryType } from 'constants/faq-entry'

const ItemCurrencyConversionNote = () => {
  const translate = useTranslate('item')

  const values = {
    'currency-conversion-note-link': (chunks: Array<ReactNode>) => (
      <FaqEntryUrl
        type={FaqEntryType.CrossCurrency}
        accessChannel={AccessChannel.ProductLink}
        render={url => (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            data-testid="cross-currency-faq-entry-link"
          >
            {chunks}
          </a>
        )}
      />
    ),
  }

  return (
    <>
      <Text text={translate('currency_conversion_note', values)} type={Text.Type.Subtitle} />
      <Spacer size={Spacer.Size.Medium} />
    </>
  )
}

export default ItemCurrencyConversionNote

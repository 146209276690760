import { AttributeType, ItemDto, ItemPageAttributesDto } from 'types/dtos'

import {
  ItemPageAttributesBlockPluginModel,
  ItemPageDynamicAttributesBlockPluginModel,
} from './types'

const SUPPORTED_ATTRIBUTE_TYPES = Object.values(AttributeType)

export const getItemPageAttributesBlockPluginModel = (
  itemDto: ItemDto,
): ItemPageAttributesBlockPluginModel => ({
  brandDto: itemDto.brand_dto,
  user: itemDto.user,
  id: itemDto.id,
  measurementWidth: itemDto.measurement_width,
  measurementLength: itemDto.measurement_length,
  measurementUnit: itemDto.measurement_unit,
  color1: itemDto.color1,
  color2: itemDto.color2,
  acceptedPayInMethods: itemDto.accepted_pay_in_methods,
  descriptionAttributes: itemDto.description_attributes,
  activeBidCount: itemDto.active_bid_count,
  size: itemDto.size_title || '',
  status: itemDto.status,
  videoGameRating: itemDto.video_game_rating,
  sizeGuideFaqEntryId: itemDto.size_guide_faq_entry_id,
  author: itemDto.author,
  bookTitle: itemDto.book_title,
  isbn: itemDto.isbn,
  viewCount: itemDto.view_count,
  favouriteCount: itemDto.favourite_count,
  createdAtTs: itemDto.created_at_ts,
  manufacturer: itemDto.manufacturer,
  manufacturerLabel: itemDto.manufacturer_labelling,
})

export const getItemPageDynamicAttributesBlockPluginModel = (
  attributesPluginDto: ItemPageAttributesDto,
): ItemPageDynamicAttributesBlockPluginModel => ({
  attributes: attributesPluginDto.attributes.filter(attribute =>
    SUPPORTED_ATTRIBUTE_TYPES.includes(attribute.type),
  ),
  itemId: attributesPluginDto.item_id,
})

'use client'

import { ItemPagePluginEntityDto } from 'types/dtos'
import useAbTest from 'hooks/useAbTest'
import useAbTestExposeTracking from 'hooks/useAbTestExposeTracking'
import { AbTestVariant } from 'constants/abtest'

import ItemPageClosetPlugin from '../plugins/Closet'
import { ItemPageClosetPluginDto } from '../plugins/Closet/types'
import ItemPageSimilarItemsPlugin from '../plugins/SimilarItems'
import { ItemPageSimilarItemsPluginModel } from '../plugins/SimilarItems/types'
import { ItemPageShopBundlesBlockPluginModel } from '../plugins/ShopBundlesBlock/types'
import ItemPageShopBundlesBlockPlugin from '../plugins/ShopBundlesBlock'
import ItemPageClosetTitlePlugin from '../plugins/ClosetTitle'
import { ItemPageClosetTitlePluginModel } from '../plugins/ClosetTitle/types'
import Plugin from '../plugins/Plugin'
import { PluginName } from '../types'

type Props = {
  referrerScreen: string
  closetData: ItemPageClosetPluginDto | undefined
  similarItemsData: ItemPageSimilarItemsPluginModel | undefined
  shopBundlesBlockData: ItemPageShopBundlesBlockPluginModel | undefined
  closetTitleData: ItemPageClosetTitlePluginModel | undefined
  serverSidePlugins?: Array<ItemPagePluginEntityDto>
}

const Feed = ({
  referrerScreen,
  closetData,
  similarItemsData,
  shopBundlesBlockData,
  closetTitleData,
  serverSidePlugins,
}: Props) => {
  const horizontalScrollAbTest = useAbTest({ abTestName: 'item_page_horizontal_scroll' })
  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q3' })?.variant === AbTestVariant.On
  const { trackExpose: trackHorizontalScrollExpose } = useAbTestExposeTracking({
    abTestName: 'item_page_horizontal_scroll',
    shouldTrackOnce: true,
  })

  const horizontalScrollEnabled =
    horizontalScrollAbTest?.variant === AbTestVariant.On && buyerHoldoutAbTestEnabled

  const handleExposureView = (inView: boolean) => {
    if (!inView || !horizontalScrollAbTest || !buyerHoldoutAbTestEnabled) return

    trackHorizontalScrollExpose()
  }

  return (
    <div className="u-ui-margin-top-medium">
      <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Feedbacks)} />
      <ItemPageClosetTitlePlugin data={closetTitleData} showSuffix={horizontalScrollEnabled} />
      {shopBundlesBlockData && <ItemPageShopBundlesBlockPlugin data={shopBundlesBlockData} />}
      {closetData && (
        <ItemPageClosetPlugin
          data={closetData}
          referrerScreen={referrerScreen}
          horizontalScrollEnabled={horizontalScrollEnabled}
          trackExposure={handleExposureView}
        />
      )}
      <ItemPageSimilarItemsPlugin
        data={similarItemsData}
        horizontalScrollEnabled={horizontalScrollEnabled}
        trackExposure={handleExposureView}
      />
    </div>
  )
}

export default Feed

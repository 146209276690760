'use client'

import { useState } from 'react'
import { Text, Cell, Button, Icon } from '@vinted/web-ui'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import DelayedPublicationModal from 'components/DelayedPublicationModal'
import useTranslate from 'hooks/useTranslate'

const DelayedPublicationStatusBox = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const translate = useTranslate('delayed_publication.status_box')

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleInfoClick = () => {
    setIsModalVisible(true)
  }

  return (
    <>
      <Cell theme="amplified" testId="item-status-delayed-publication">
        <div className="u-flexbox u-align-items-center">
          <Text text={translate('title')} type={Text.Type.Title} theme="inherit" inline />
          <Button
            icon={<Icon name={InfoCircle16} color={Icon.Color.GreyscaleLevel5} />}
            size={Button.Size.Small}
            styling={Button.Styling.Flat}
            onClick={handleInfoClick}
            title="Show additional information"
            inline
          />
        </div>
      </Cell>
      <DelayedPublicationModal show={isModalVisible} onClose={handleModalClose} />
    </>
  )
}

export default DelayedPublicationStatusBox

'use client'

import { useSelector } from 'react-redux'
import { Divider, Spacer } from '@vinted/web-ui'

import { getUserId } from 'state/session/selectors'
import BundleEntryBanner from 'components/BundleEntryBanner'
import { isBundlingAvailable } from 'libs/utils/bundle'

import { ItemPageShopBundlesBlockPluginModel } from './types'

type Props = {
  data: ItemPageShopBundlesBlockPluginModel
}

const ItemPageShopBundlesBlockPlugin = ({ data }: Props) => {
  const currentUserId = useSelector(getUserId)

  const {
    canBundle,
    closetCountdownEndDate,
    itemId,
    itemUserId,
    userItemCount,
    isUserHated,
    isUserHatesYou,
    bundleDiscount,
    canBuyItem,
  } = data

  const isViewingOwnProfile = itemUserId === currentUserId

  const showPlugin = isBundlingAvailable({
    canBundle,
    totalItemCount: userItemCount,
    isCurrentUserAuthenticated: !!currentUserId,
    closetCountdownEndDate,
    hasHateFromAnySide: isUserHated || isUserHatesYou,
    isViewingOwnProfile,
  })

  if (!showPlugin) return null

  return (
    <>
      <Divider />
      <BundleEntryBanner
        itemId={itemId}
        canBuyItem={canBuyItem}
        itemUserId={itemUserId}
        bundleDiscount={bundleDiscount}
        testId="item-buy-bundle-banner"
      />
      <Spacer />
    </>
  )
}

export default ItemPageShopBundlesBlockPlugin

'use client'

import { Button, Spacer, Text } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  title: string
  url: string
  subtitle: string
  bottomLinkTitle: string
  bottomLinkUrl: string
}

const PortalMergeItemViewBanner = ({
  title,
  url,
  subtitle,
  bottomLinkTitle,
  bottomLinkUrl,
}: Props) => {
  const { track } = useTracking()

  function trackClick(target: ClickableElement) {
    track(clickEvent({ target }))
  }

  function trackActionClick() {
    trackClick(ClickableElement.MergeAnnouncementAction)
  }

  function trackSubtitleClick() {
    trackClick(ClickableElement.MergeAnnouncementLink)
  }

  return (
    <>
      <Spacer size={Spacer.Size.Large} />
      <Button text={title} url={url} styling={Button.Styling.Filled} onClick={trackActionClick} />
      <Spacer />

      <Text type={Text.Type.Subtitle} alignment={Text.Alignment.Center} width={Text.Width.Parent}>
        {`${subtitle} `}
        <a href={bottomLinkUrl} className="u-no-wrap" onClick={trackSubtitleClick}>
          {bottomLinkTitle}
        </a>
      </Text>
    </>
  )
}

export default PortalMergeItemViewBanner

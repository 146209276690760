'use client'

import { Spacer, Text } from '@vinted/web-ui'

import useLocation from 'hooks/useLocation'
import { getContentWithThreadId } from 'libs/utils/faq'

import HelpCenterFaqEntryTrackingEvent from '../HelpCenterFaqEntryTrackingEvent'

type Props = {
  faqEntryId: number
  title: string | null | undefined
  content: string | null | undefined
  xhrRequest?: boolean
  accessChannel?: string
}

const FaqContent = ({ faqEntryId, title, content, xhrRequest = false, accessChannel }: Props) => {
  const { thread_id } = useLocation().searchParams

  return (
    <article className="faq__question-answer faq-content faq-content--accordion">
      <HelpCenterFaqEntryTrackingEvent faqEntryId={faqEntryId} accessChannel={accessChannel} />
      {!xhrRequest && <Spacer />}
      <Text type={Text.Type.Heading} text={title} />
      <Spacer size={Spacer.Size.Large} />
      {!!content && (
        <Text text={getContentWithThreadId(content, thread_id)} width={Text.Width.Parent} html />
      )}
    </article>
  )
}

export default FaqContent
